import classnames from 'classnames';
import { getStaticResourceUrl } from 'egenie-common';
import React from 'react';
import styles from './plusServiceImgContainer.less';
import { Typography } from 'antd';

interface PlusServiceImgContainerProps {
  src: string;
  title: string;
  imgHeight: number | string;
  className?: string;
  style?: React.CSSProperties;
}

export const PlusServiceImgContainer: React.FC<PlusServiceImgContainerProps> = function({
  src,
  title,
  className = '',
  style,
  imgHeight,
}) {
  return (
    <section
      className={classnames(className, styles.plusServiceImgContainer)}
      style={style}
    >
      <header>
        {title}
      </header>
      <footer>
        <div
          className="egenie-common-img-fixed-shape"
          style={{
            height: imgHeight,
            backgroundImage: `url(${src ? src : getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/qualityTestEmpty.png')})`,
          }}
        />
      </footer>
    </section>
  );
};

interface PlusServiceCellContainer {
  className?: string;
  style?: React.CSSProperties;
  value?: string | number;
}

export const PlusServiceCellContainer: React.FC<PlusServiceCellContainer> = function({
  className = '',
  style,
  value,
}) {
  return (
    <div
      className={classnames(className, styles.plusServiceCellContainer)}
      style={style}
    >
      <Typography.Text
        ellipsis
        title={`${value || ''}`}
      >
        {value}
      </Typography.Text>
    </div>
  );
};
