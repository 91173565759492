import type { InputRef } from 'antd';
import { message, Modal } from 'antd';
import { printWayBill, getStaticResourceUrl, printHelper } from 'egenie-common';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, computed, observable } from 'mobx';
import React from 'react';
import type { PrintStatistics, SortBind, Wave } from './interface';
import { playVoice } from '../../utils';
import _ from 'lodash';

const voiceList = {};

export class Store {
  constructor() {
    this.init();
  }

  @observable public barCode = '';

  @action public handleBarCodeChange = (e) => {
    this.barCode = e.target.value;
  };

  public barCodeRef: React.RefObject<InputRef> = React.createRef<InputRef>();

  @action public init = (): void => {
    this.waveList = [];
    this.waveValue = undefined;
    this.waveSuccessData = {};
    this.sortConfig = { sortVoice: 0 };
    this.printStatistics = {
      skuNum: 0,
      sortedSkuNum: 0,
      sortedTotalWmsOrderNum: 0,
      totalWmsOrderNum: 0,

      unSortedSkuNum: 0,
      unSortedTotalWmsOrderNum: 0,
    };
  };

  // 波次号列表
  @observable public waveList: Wave['orderList'];

  // 选中波次
  @observable public waveValue: string;

  // 分拣成功数据
  @observable public waveSuccessData: SortBind;

  // 波次分拣配置
  @observable public sortConfig: Wave['sortConfig'];

  // 统计数据
  @observable public printStatistics: PrintStatistics;

  @computed
  public get printButtonText() {
    if (this.waveSuccessData?.deliveryByBox === true) {
      return '打印箱单';
    }
    return '打印格子内电子面单';
  }

  // 获取声音资源
  @action public getVoiceData = () => {
    const sortVoice = this.sortConfig.sortVoice;
    const nameScope = [
      sortVoice,
      'normal',
    ].join('_');

    if (voiceList[nameScope]) {
      playVoice(voiceList[nameScope]['23102']);
    } else {
      request<BaseData<{[key: string]: string; }>>({ url: `/api/cloud/wms/rest/voice/getVoiceData/${sortVoice}/normal` })
        .then((res) => {
          voiceList[nameScope] = Object.entries(res.data || {})
            .reduce((previousValue, currentValue) => {
              previousValue[currentValue[0]] = `data:audio/mpeg;base64,${currentValue[1]}`;
              return previousValue;
            }, {});
          playVoice(voiceList[nameScope]['23102']);
        });
    }
  };

  @action public getAndPlayVoice = (tex: string, group: string, voicePrefix: string | number, match: string | number) => {
    const sortVoice = this.sortConfig.sortVoice;
    const nameScope = [
      sortVoice,
      'normal',
    ].join('_');

    if (!tex || !voicePrefix) {
      return;
    }

    let uriIndex = '';
    if ('23' === match) {
      uriIndex = `${match}${voicePrefix}`;
    } else if ('22' === match || match === '20') {
      uriIndex = `${match}${sortVoice}${voicePrefix}`;
    } else if ('21' === match) {
      uriIndex = `${match}${sortVoice}${group}${voicePrefix}`;
    } else if ('24' === match) {
      uriIndex = uriIndex = `${match}${sortVoice}${group}${voicePrefix}p`;
    } else if ('25' === match) {
      uriIndex = `${match}${sortVoice}${voicePrefix}p`;
    }
    if (voiceList[nameScope]?.[uriIndex]) {
      playVoice(voiceList[nameScope][uriIndex]);
    } else {
      const voiceData = {
        tex,
        spd: '6',
        ctp: '1',
        per: sortVoice,
        pit: '7',
        vol: '15',
        group,
        cell: voicePrefix,
        voice: match,
        vt: tex,
        vc: voicePrefix,
        urlIndex: uriIndex,
      };
      request<BaseData<string>>({
        method: 'post',
        url: '/api/cloud/wms/rest/voice/getVoiceUrlByCache',
        data: voiceData,
      })
        .then((res) => {
          if (res.data) {
            playVoice(`data:audio/mpeg;base64,${res.data}`);
          }
        });
    }
  };

  @observable public isGetWavData = false;

  // 获取波次
  @action public getWaveData = () => {
    this.isGetWavData = true;
    request<BaseData<Wave>>({ url: '/api/cloud/wms/rest/sort/query/cond' })
      .then((res) => {
        // 重新初始化相关数据
        this.init();

        this.sortConfig = res.data.sortConfig;
        this.waveList = (res.data?.orderList || []).map((item) => ({
          ...item,
          value: `${item.wmsPickingOrderId}`,
          label: `${item.serialNo}${item.waveName}`,
        }));
      })
      .finally(() => this.isGetWavData = false);
  };

  // 选择波次 & 播放声音
  @action public handleWaveAndGroupValue = (waveValue: string) => {
    this.waveValue = waveValue;
    if (this.waveValue) {
      this.sortPrintStatistics();
    }
  };

  // 分拣
  @action public handleSort = () => {
    const data = {
      barCode: this.barCode,
      delivery: this.sortConfig.printDelivery ? '1' : '0',
      groupNo: this.waveValue || '',
      number: 1,
      orderType: this.waveSuccessData?.orderType,
      pickOrderNo: this.waveValue || '',
      waveId: this.waveSuccessData?.waveId,
    };

    this.barCodeRef.current?.focus();
    this.barCode = '';

    if (!data.barCode) {
      this.getAndPlayVoice('请扫描条形码', '202', '23', '0');
      this.waveSuccessData = {};
      this.waveSuccessData.reason = '请扫描条形码';
      return;
    }

    request<BaseData<SortBind>>({
      method: 'post',
      url: '/api/cloud/wms/rest/sort/sorting/bind',
      data,
    })
      .then((res) => {
        this.waveSuccessData = {
          ...this.waveSuccessData,
          ...res.data,
        };
        this.sortPrintStatistics();

        // 打印发货单
        if (res?.data?.wmsOrderPrintData) {
          printHelper.toggleToLodop();
          printHelper.print({
            preview: false,
            templateData: res?.data?.wmsOrderPrintData?.tempData,
            contents: res?.data?.wmsOrderPrintData?.userDataList,
          });
        }
        if (res.data.deliveryByBox && res.data.printBoxOrder) {
          this.printBox();
        }
        if (res?.data?.sortedStatus) { // 当前分拣是否成功
          this.handleVoiceAndPrint(this.waveSuccessData);
        } else {
          this.getAndPlayVoice(res.data.reason, '', res.data.voiceUri, res.data.match);
        }
      })
      .catch(() => {
        message.destroy();
        this.getAndPlayVoice('分拣错误', '', '100', '23');
        this.waveSuccessData = {};
        this.waveSuccessData.reason = '分拣错误';
      });
  };

  @action public handleVoiceAndPrint = (prop: SortBind) => {
    if (prop.sellerMemo && this.sortConfig.sellerMemoNotice) {
      playVoice(getStaticResourceUrl('customer-source/ring/didi.mp3'));
    }

    // 单条打印
    if (prop.print) {
      this.getAndPlayVoice(prop.voice, prop.groupNo, prop.voiceUri, prop.match);
      this.handlePrintSingle(prop, false);
      return;
    }

    // 多条报格子号
    this.getAndPlayVoice(prop.voice, prop.groupNo, prop.cellNo, prop.match);
  };

  @action public sortPrintStatistics = () => {
    if (this.waveValue) {
      request<BaseData<PrintStatistics>>({ url: `/api/cloud/wms/rest/wave/statistic/query/${this.waveValue}` })
        .then((res) => {
          const {
            skuNum,
            sortedSkuNum,
            sortedTotalWmsOrderNum,
            totalWmsOrderNum,
            unSortedSkuNum,
            unSortedTotalWmsOrderNum,
          } = res.data;
          this.printStatistics = {
            skuNum: skuNum || 0,
            sortedSkuNum: sortedSkuNum || 0,
            sortedTotalWmsOrderNum: sortedTotalWmsOrderNum || 0,
            totalWmsOrderNum: totalWmsOrderNum || 0,
            unSortedSkuNum: unSortedSkuNum ?? 0,
            unSortedTotalWmsOrderNum: unSortedTotalWmsOrderNum ?? 0,
          };
        });
    }
  };

  // 自动聚焦商品条码
  @action public onDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      this.barCodeRef.current?.focus();
    }
  };

  // 重新打印
  @action public handleRePrint = () => {
    if (!this.waveSuccessData.wmsOrderId) {
      return;
    }

    if (this.waveSuccessData.wmsOrderType) {
      Modal.warn({ title: '多件分拣,不可打印' });
      return;
    }

    Modal.confirm({
      title: '当前快递单是否重新打印 ？',
      onOk: async() => {
        this.waveSuccessData.waybillData = null;
        this.waveSuccessData.waybillData = null;
        this.barCodeRef.current?.focus();
        await this.handlePrintSingle(this.waveSuccessData, true);
      },
    });
  };

  // 单条打印
  @action public handlePrintSingle = async(prop: SortBind, isRePrint: boolean) => {
    if (!prop || Object.keys(prop).length === 0) {
      throw new Error('没有数据');
    }

    if (isRePrint) {
      // 重新打印
      await printWayBill.getDataAndPrint({
        printSrc: '19',
        userDataIds: prop.wmsOrderId, // 发货单id
        tempType: 0,
        printType: '0', // 打印类型
        preview: false,
      });
    } else {
      // 分拣打印
      if (!prop.waybillData || !prop.waybillData.success || !prop.waybillData.data || !prop.waybillData.data.length) {
        message.error({
          key: '打印失败',
          content: '打印失败',
        });
        throw new Error('打印失败');
      }
      await printWayBill.executePrint({
        printSrc: '2',
        userDataIds: prop.wmsOrderId, // 发货单id
        tempType: 0,
        printType: '0', // 打印类型
        preview: false,
        checkPrint: false,
      }, prop.waybillData.data);
    }
  };

  // 打印全部
  @action public handlePrintAll = () => {
    if (!this.waveValue) {
      const error = '请选择波次';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      title: '将打印全部分拣完成的快递单,请确认商品是否扫描完成(分拣完成后将自动移除未打印快递单)',
      onOk: async() => {
        if (this.waveSuccessData.deliveryByBox) {
          await this.printBox();
        } else {
          const res = await request<BaseData<{ courierPintData?: any[]; wmsOrderPrintData?: { tempData?: any; userDataList?: any[]; }; }>>({
            method: 'post',
            url: '/api/cloud/wms/rest/order/picking/getDeliveryPrintData',
            data: {
              printSrc: '2',
              tempType: 0,
              printType: '0', // 打印类型
              userDataIds: this.waveValue,
              type: '1',
              group: '',
            },
          });

          // 打印发货单
          if (res?.data?.wmsOrderPrintData) {
            printHelper.toggleToLodop();
            printHelper.print({
              preview: false,
              templateData: res?.data?.wmsOrderPrintData?.tempData,
              contents: res?.data?.wmsOrderPrintData?.userDataList,
            });
          }

          await printWayBill.executePrint({
            printSrc: '2',
            tempType: 0,
            printType: '0', // 打印类型
            preview: false,
          }, res?.data.courierPintData);
        }
      },
    });
  };

  public printBox = async() => {
    try {
      const res = await request<BaseData<{ tempData: any;userDataList: any; }>>({
        method: 'POST',
        url: '/api/cloud/wms/rest/box/print',
        data: {
          wmsPickingOrderId: this.waveValue,
          printType: 0,
        },
      });
      if (!_.isEmpty(res.data?.tempData) && !_.isEmpty(res.data?.userDataList)) {
        printHelper.toggleToRookieCustom();
        printHelper.print({
          preview: false,
          templateData: res?.data?.tempData,
          contents: res?.data?.userDataList,
        });
      }
    } catch (error) {
      throw new Error('箱单打印失败');
    }
  };
}

