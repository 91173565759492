import { Input, Row, Col, Select, Button, Space, Typography } from 'antd';
import { egeniePcTheme, getStaticResourceUrl, thousandthSeparator } from 'egenie-common';
import { Permission } from 'egenie-utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';
import { PlusServiceCellContainer, PlusServiceImgContainer } from '../saleCheck/plusServiceImgContainer/plusServiceImgContainer';

@observer
class WaveSelect extends React.Component<{ store: Store; }> {
  render() {
    const {
      waveList,
      onDropdownVisibleChange,
      handleWaveAndGroupValue,
      handlePrintAll,
      waveValue,
      isGetWavData,
      getWaveData,
      printButtonText,
    } = this.props.store;

    return (
      <div className={styles.headerContainer}>
        <span className={styles.blueLine}/>
        <Select
          allowClear
          autoFocus
          className={styles.selectWave}
          defaultOpen
          filterOption={(inputValue: string, path) => {
            return [
              _.toString(path?.cartNo)
                .toLowerCase(),
              _.toString(path?.serialNo)
                .toLowerCase(),
            ].some((item) => item.includes(inputValue.toLowerCase()));
          }}
          onChange={handleWaveAndGroupValue}
          onDropdownVisibleChange={onDropdownVisibleChange}

          // @ts-ignore
          options={waveList}
          placeholder="请选择波次号"
          showSearch
          suffixIcon={<span className="icon-arrow_pulldown"/>}
          value={waveValue}
        />
        <Space size="middle">
          <Permission permissionId="1649_712">
            <Button
              className="ghost-bg-btn"
              loading={isGetWavData}
              onClick={getWaveData}
            >
              刷新波次
            </Button>
          </Permission>
          <Permission permissionId="1649_713">
            <Button
              className={`${styles.printAll} ghost-bg-btn`}
              onClick={handlePrintAll}
            >
              {printButtonText}
            </Button>
          </Permission>
        </Space>
      </div>
    );
  }
}

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      waveSuccessData,
      barCodeRef,
      printStatistics,
      handleSort,
      barCode,
      handleBarCodeChange,
    } = this.props.store;
    return (
      <div className={styles.left}>
        <div className={styles.bigCard}>
          <div className={styles.header}>
            <div className={styles.tag}>
              波次分拣
            </div>
          </div>
          <div className={styles.content}>
            <Row>
              <Col span={12}>
                <h2>
                  待分拣订单
                </h2>
                <div className={styles.waitCheck}>
                  <span className={styles.currentCount}>
                    {printStatistics.unSortedTotalWmsOrderNum}
                  </span>
                </div>
                <div className={styles.process}>
                  <span>
                    进度:&nbsp;
                  </span>
                  <Typography.Text ellipsis>
                    {thousandthSeparator(printStatistics?.sortedTotalWmsOrderNum)}
                    /
                    {thousandthSeparator(printStatistics.totalWmsOrderNum)}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={12}>
                <h2>
                  待分拣sku数量
                </h2>
                <div className={styles.waitCheck}>
                  <span className={styles.currentCount}>
                    {printStatistics.unSortedSkuNum}
                  </span>
                </div>
                <div className={styles.process}>
                  <span>
                    进度:&nbsp;
                  </span>
                  <Typography.Text ellipsis>
                    {thousandthSeparator(printStatistics?.sortedSkuNum)}
                    /
                    {thousandthSeparator(printStatistics.skuNum)}
                  </Typography.Text>
                </div>
              </Col>
            </Row>
            <h2 style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}>
              请扫描商品条码
            </h2>
            <Input
              autoComplete="off"
              id="productCode"
              onChange={handleBarCodeChange}
              onPressEnter={handleSort}
              placeholder="商品唯一码/sku编码/条形码"
              ref={barCodeRef}
              size="large"
              value={barCode}
            />
            <div className={styles.checkProgress}>
              {waveSuccessData.reason}
            </div>
            <div className={styles.waybillCode}>
              <Typography.Text
                copyable={Boolean(waveSuccessData.waybillCode)}
                title={waveSuccessData.waybillCode || ''}
              >
                {waveSuccessData.waybillCode || ''}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const {
      waveSuccessData,
      handleRePrint,
    } = this.props.store;

    const data: Array<{ name: string; value: string | number; valueStyle?: React.CSSProperties; }> = [
      {
        name: '店铺名称',
        value: waveSuccessData?.shopName || '',
      },
      {
        name: '快递信息',
        value: `${waveSuccessData?.courierNo || ''} ${waveSuccessData?.courierName ? `(${waveSuccessData?.courierName})` : ''}`,
      },
      {
        name: '平台状态',
        value: waveSuccessData?.platformStatus,
      },
      {
        name: '付款时间',
        value: waveSuccessData?.payTime,
      },
      {
        name: '买家留言',
        value: waveSuccessData?.buyerMessage,
      },
      {
        name: '客服备注',
        value: waveSuccessData?.sellerMemo,
        valueStyle: { color: egeniePcTheme.color['egenie-highlight-color'] },
      },
    ];
    return (
      <div className={styles.right}>
        <div className={styles.bigCard}>
          <div className={styles.header}>
            <div className={styles.left}>
              <h3>
                打印结果
              </h3>
            </div>
            <Permission permissionId="1649_714">
              <div
                className={`${styles.title} ${styles.refresh}`}
                onClick={handleRePrint}
              >
                重新打印面单
              </div>
            </Permission>
          </div>
          <div className={styles.content}>
            <Row
              gutter={[
                20,
                20,
              ]}
              style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
            >
              <Col span={8}>
                <div className={styles.skuDetailContainer}>
                  <Typography.Title
                    ellipsis={{ rows: 2 }}
                    level={3}
                    title={waveSuccessData?.skuNo || ''}
                  >
                    {waveSuccessData?.skuNo}
                  </Typography.Title>
                  <div
                    className={`${styles.imgContainer} egenie-common-img-fixed-shape`}
                    style={{ backgroundImage: `url(${waveSuccessData.skuPic ? waveSuccessData?.skuPic : getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/qualityTestEmpty.png')})` }}
                  />
                </div>

                <PlusServiceCellContainer
                  style={{ height: 88 }}
                  value={`${waveSuccessData?.groupNo ? `${waveSuccessData?.groupNo}组` : ''} ${waveSuccessData?.cellNo ? `${waveSuccessData?.cellNo}格` : ''}`}
                />
              </Col>
              <Col span={8}>
                <PlusServiceImgContainer
                  imgHeight={560}
                  src={waveSuccessData?.changePackagePic}
                  style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
                  title="包材"
                />
                <PlusServiceCellContainer
                  style={{ height: 88 }}
                  value={waveSuccessData?.changePackageTip}
                />
              </Col>
              <Col span={8}>
                <PlusServiceImgContainer
                  imgHeight={560}
                  src={waveSuccessData?.goodCardPic}
                  style={{ marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'] }}
                  title="好评卡"
                />
                <PlusServiceCellContainer
                  style={{ height: 88 }}
                  value={waveSuccessData?.goodCardTip}
                />
              </Col>
            </Row>
            <div className={styles.detail}>
              <Row
                gutter={[
                  16,
                  16,
                ]}
              >
                {
                  data.map((item) => (
                    <Col
                      key={item.name}
                      span={6}
                    >
                      <Typography.Text
                        ellipsis
                        style={{ maxWidth: '100%' }}
                        title={_.toString(item.value)}
                      >
                        {item.name}
                        :
                        <span
                          className={styles.detailValue}
                          style={item.valueStyle}
                        >
                          {item.value}
                        </span>
                      </Typography.Text>
                    </Col>
                  ))
                }
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  React.useEffect(() => {
    store.getWaveData();
    store.getVoiceData();
  }, []);

  return (
    <div className={styles.wavePick}>
      <WaveSelect store={store}/>
      <div className={styles.wavePickContainer}>
        <Left store={store}/>
        <Right store={store}/>
      </div>
    </div>
  );
}
